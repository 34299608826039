import { Link, Outlet } from "@remix-run/react";
import stars from "../styles/stars.css";

export function links() {
  return [{ rel: "stylesheet", href: stars }];
}

export default function MarketingLayout() {
  return (
    <div className="flex min-h-screen flex-col overflow-hidden antialiased bg-[#0a0a0b] text-white">
      <header className="fixed z-30 w-full bg-opacity-30 transition duration-300 ease-in-out backdrop-blur">
        <div className="mx-auto container px-4 sm:px-6">
          <div className="flex h-16 items-center justify-between md:h-20">
            <div className="mr-4 flex-shrink-0">
              <Link
                to="/"
                className="block text-white font-extrabold uppercase tracking-tighter"
              >
                Diffs
              </Link>
            </div>
          </div>
        </div>
      </header>

      <Outlet />

      <footer className="w-full bg-black text-white flex flex-col border-zinc-800">
        <div className="mt-0 container mx-auto px-4">
          <div className="border-zinc-800 py-8 flex flex-col sm:flex-row gap-6">
            <div className="flex-1 flex flex-col gap-2">
              <span className="text-zinc-400 text-sm">Made in Vienna</span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
